import React from "react";
// react plugin used to create charts
import { Bar } from "react-chartjs-2";
// reactstrap components
import { Card, CardHeader, CardBody, Row } from "reactstrap";
import { chartExample2 } from "../../variables/charts.js";
function ChartBar(props) {
  return (
    <div>
      <Card className="shadow">
        <CardHeader className="bg-transparent">
          <Row className="align-items-center">
            <div className="col">
              <h6 className="text-uppercase text-muted ls-1 mb-1">
                Distribution
              </h6>
              <h2 className="mb-0">Total students</h2>
            </div>
          </Row>
        </CardHeader>
        <CardBody>
          {/* Chart */}
          <div className="chart">
            <Bar data={props.chartData.data} options={chartExample2.options} />
          </div>
        </CardBody>
      </Card>
    </div>
  );
}

export default ChartBar;
