const chartData = {
  data: {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Students Distribution",
        data: new Array(12).fill(0),
        maxBarThickness: 10,
      },
    ],
  },
};

const currentDate = new Date();
const startYearDate = new Date(currentDate.getFullYear(), 0, 1, 0, 0, 0);

/**
 *
 * @param {Int} month Specific month
 * @param {Int} year Specific year
 * @returns returns the number of days in that month in the current year
 */
function getDaysInMonth(month, year) {
  return /8|3|5|10/.test(--month)
    ? 30
    : month == 1
    ? (!(year % 4) && year % 100) || !(year % 400)
      ? 29
      : 28
    : 31;
}
/**
 *
 * @param {Int} startYear The year to start collection data
 * @param {Int} endYear Get data from 'startYear' untill 'endYear'
 * @return {Array of JSON's} Returns an Array of the following form:
 * [{"start": unixStart, "end":unixEnd}, {"start": unixStart, "end":unixEnd}, ..., {"start": unixStart, "end":unixEnd]}]
 * (12 entries, first represents the month January, second represents February,...)
 * Where unixStart and unixEnd represent the unix timestamp of the start and end of each month respectively
 */
function getDataRange(startYear, endYear) {
  let monthTimestamps = [];
  let monthData = { start: 0, end: 0 };
  let numOfDays = 0;
  let startOfMonth = null;
  let endOfMonth = null;
  for (let month = 0; month <= 11; month++) {
    numOfDays = getDaysInMonth(month + 1, startYear);
    startOfMonth = new Date(startYear, month, 1, 0, 0, 0);
    endOfMonth = new Date(startYear, month, numOfDays, 23, 59, 59);
    monthData.start = Math.floor(startOfMonth.getTime() / 1000);
    monthData.end = Math.floor(endOfMonth.getTime() / 1000);

    monthTimestamps.push({ ...monthData });
  }

  return monthTimestamps;
}

function parseDataRange(dataDistr) {
  let studentsDistr = new Array(12).fill(0);

  dataDistr.forEach((data) => {
    //studentsDistr[data.Month - 1] += data.Num;
    studentsDistr[data.Month - 1] = data.Num;
  });

  let newChart = { ...chartData };
  newChart.data.datasets[0].data = studentsDistr;
  return newChart;
}

export { parseDataRange, chartData, startYearDate, currentDate };
