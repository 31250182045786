import React, { useState, useEffect } from "react";

import Chart from "chart.js";

import { Container, Row, Col } from "reactstrap";
import axios from "axios";
import HeaderHandler from "../handlers/HeaderHandler";
import StudentsDistrHandler from "../handlers/StudentsDistrHandler";
import ChartLine from "../components/comp/ChartLine";
import ChartBar from "../components/comp/ChartBar";
import TableComp from "../components/comp/TableComp";
import DatePicker from "../components/comp/DatePicker";
import RangeDatePicker from "../components/comp/RangeDatePicker";
import TableProgress from "components/comp/TableProgress";
import { chartOptions, parseOptions } from "variables/charts.js";

import Header from "components/Headers/Header.js";
import { unix } from "moment";

const Index = (props) => {
  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }
  const [users, setData] = useState([]);
  const [courses, setCourses] = useState([]);

  const [unixTime, setUnixTime] = useState(Math.floor(Date.now() / 1000));

  useEffect(async () => {
    let isSubscribed = true;
    const response = await axios.get(
      "http://mimapp.mimshakup.com/api/teachers/get-courses"
    );
    // const response = await axios.get(
    //   "http://localhost:7000/api/teachers/get-courses"
    // );
    if (isSubscribed) {
      setCourses(response.data);
    }

    return () => (isSubscribed = false);
  }, []);

  useEffect(async () => {
    let isSubscribed = true;
    const response = await axios.get(
      "http://mimapp.mimshakup.com/api/teachers/get-students"
    );
    // const response = await axios.get(
    //   "http://localhost:7000/api/teachers/get-students"
    // );
    //console.log(response.data);
    if (isSubscribed) {
      setData(response.data);
    }

    return () => (isSubscribed = false);
  }, []);

  function handleUnix(unixTime) {
    let isSubscribed = true;
    if (isSubscribed) {
      setUnixTime(unixTime);
    }

    return () => (isSubscribed = false);
  }

  return (
    <>
      <HeaderHandler unixTime={unixTime}></HeaderHandler>

      <Container className="mt--7" fluid>
        <DatePicker handleUnix={handleUnix}></DatePicker>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="6">
            <TableComp data={courses} user={false} />
          </Col>
          <Col className="mb-5 mb-xl-0" xl="6">
            <TableComp data={users} user={true} />
          </Col>
        </Row>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            <StudentsDistrHandler></StudentsDistrHandler>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Index;
