import React, { useState, useEffect } from "react";
import { Card, CardHeader, Table, Button, Row } from "reactstrap";
import moment from "moment";
function TableComp(props) {
  useEffect(() => {
    setData(props.data);
  }, [props.data]);
  const [data, setData] = useState(props.data);
  const [expend, setexpend] = useState(false);
  return (
    <div>
      <Card className="shadow">
        <CardHeader className="border-0">
          <Row className="align-items-center">
            <div className="col text-left">
              <Button onClick={() => setexpend(!expend)}>
                {expend ? "סגור" : "הצג הכל"}
              </Button>
            </div>
            <div className="col text-right">
              <h3 className="mb-0">{props.user ? "תלמידים" : "קורסים"}</h3>
            </div>
          </Row>
        </CardHeader>
        <Table
          className="align-items-center table-flush"
          style={{ direction: "rtl", textAlign: "right" }}
          responsive
        >
          <thead className="thead-light">
            {props.user ? (
              <tr>
                <th scope="col">שם פרטי</th>
                <th scope="col">שם משפחה</th>
                <th scope="col">איימל</th>
              </tr>
            ) : (
              <tr>
                <th scope="col">שם הקורס</th>
                <th scope="col">תאריך יצירה</th>
              </tr>
            )}
          </thead>
          <tbody>
            {expend
              ? data.map((user) => (
                  <tr key={user.id}>
                    <th scope="row">
                      {props.user ? user.firstname : user.fullname}
                    </th>
                    <td>
                      {props.user
                        ? user.lastname
                        : moment
                            .unix(user.timecreated)
                            .format("DD-MM-YYYY HH:mm")}
                    </td>
                    {props.user && <td>{user.email}</td>}
                  </tr>
                ))
              : data.slice(0, 10).map((user) => (
                  <tr key={user.id}>
                    <th scope="row">
                      {props.user ? user.firstname : user.fullname}
                    </th>
                    <td>
                      {props.user
                        ? user.lastname
                        : moment
                            .unix(user.timecreated)
                            .format("DD-MM-YYYY HH:mm")}
                    </td>
                    {props.user && <td>{user.email}</td>}
                  </tr>
                ))}
          </tbody>
        </Table>
      </Card>
    </div>
  );
}

export default TableComp;
