import React, { useState, useEffect } from "react";
import axios from "axios";
import ChartBar from "../components/comp/ChartBar";
import RangeDatePicker from "../components/comp/RangeDatePicker";
import {
  parseDataRange,
  chartData,
  startYearDate,
  currentDate,
} from "../helper-functions/get-data-range";

const StudentsDistrHandler = (props) => {
  const [usersDistrNotParsed, setUsersDistrNotParsed] = useState([]);
  const [usersDistrParsed, setUsersDistrParsed] = useState(chartData);

  const [startUnixTime, setStartUnixTime] = useState(
    Math.floor(startYearDate / 1000)
  );
  const [endUnixTime, setEndUnixTime] = useState(
    Math.floor(currentDate / 1000)
  );

  function handleStartUnix(unixTime) {
    let isSubscribed = true;
    if (isSubscribed) {
      setStartUnixTime(unixTime);
    }
    console.log(startUnixTime);

    return () => (isSubscribed = false);
  }

  function handleEndUnix(unixTime) {
    let isSubscribed = true;
    if (isSubscribed) {
      setEndUnixTime(unixTime);
    }

    return () => (isSubscribed = false);
  }

  useEffect(async () => {
    let isSubscribed = true;
    const response = await axios.get(
      "http://mimapp.mimshakup.com/api/teachers/get-students-distribution",
      { params: { startUnix: startUnixTime, endUnix: endUnixTime } }
    );
    // const response = await axios.get(
    //   "http://localhost:7000/api/teachers/get-students-distribution",
    //   { params: { startUnix: startUnixTime, endUnix: endUnixTime } }
    // );
    if (isSubscribed) {
      setUsersDistrNotParsed(response.data);
    }

    return () => (isSubscribed = false);
  }, [startUnixTime, endUnixTime]);

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      let data = parseDataRange(usersDistrNotParsed);

      setUsersDistrParsed(data);
    }

    return () => (isSubscribed = false);
  }, [usersDistrNotParsed]);
  return (
    <>
      <ChartBar chartData={usersDistrParsed}></ChartBar>
      <RangeDatePicker
        handleStartUnix={handleStartUnix}
        handleEndUnix={handleEndUnix}
        startYearDate={startYearDate}
        currentDate={currentDate}
      ></RangeDatePicker>
    </>
  );
};

export default StudentsDistrHandler;
