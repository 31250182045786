import React, { Component } from "react";
// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";

// reactstrap components
import {
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  Row,
} from "reactstrap";

class Datepicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
    };
  }

  getDate = (date) => {
    this.setState({ date: date });
    this.props.handleUnix(date.unix());
  };

  render() {
    const inputProps = {
      placeholder: "Date Picker Here",
    };
    return (
      <>
        <FormGroup>
          <InputGroup className="input-group-alternative">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="ni ni-calendar-grid-58" />
              </InputGroupText>
            </InputGroupAddon>
            <ReactDatetime
              onChange={this.getDate}
              inputProps={inputProps}
              timeFormat={false}
              initialValue={new Date()}
            />
          </InputGroup>
        </FormGroup>
      </>
    );
  }
}

export default Datepicker;
