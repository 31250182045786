import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "components/Headers/Header.js";

const HeaderHandler = (props) => {
  const [coursesNum, setCoursesNum] = useState(0);
  const [studentsNum, setStudentsNum] = useState(0);
  const [assignmentsNum, setAssignmentsNum] = useState(0);
  const [coursesTimestamp, setCoursesTimestamp] = useState(0);
  const [userEnrolments, setUserEnrolments] = useState(0);

  useEffect(async () => {
    let isSubscribed = true;
    const response = await axios.get(
      "http://mimapp.mimshakup.com/api/teachers/get-user-enrolments"
    );
    // const response = await axios.get(
    //   "http://localhost:7000/api/teachers/get-user-enrolments"
    // );
    //console.log(response.data);
    if (isSubscribed) {
      setUserEnrolments(response.data[0]["Count"]);
    }

    return () => (isSubscribed = false);
  }, []);

  useEffect(async () => {
    let isSubscribed = true;
    const response = await axios.get(
      "http://mimapp.mimshakup.com/api/teachers/get-courses-num"
    );
    // const response = await axios.get(
    //   "http://localhost:7000/api/teachers/get-courses-num"
    // );
    //console.log(response.data);
    if (isSubscribed) {
      setCoursesNum(response.data[0]["COUNT(id)"]);
    }

    return () => (isSubscribed = false);
  }, []);

  useEffect(async () => {
    let isSubscribed = true;
    const response = await axios.get(
      "http://mimapp.mimshakup.com/api/teachers/get-students-num"
    );
    // const response = await axios.get(
    //   "http://localhost:7000/api/teachers/get-students-num"
    // );
    //console.log(response.data);
    if (isSubscribed) {
      setStudentsNum(response.data[0]["COUNT(id)"]);
    }

    return () => (isSubscribed = false);
  }, []);

  useEffect(async () => {
    let isSubscribed = true;
    const response = await axios.get(
      "http://mimapp.mimshakup.com/api/teachers/get-assignments-num"
    );
    // const response = await axios.get(
    //   "http://localhost:7000/api/teachers/get-assignments-num"
    // );
    //console.log(response.data);
    if (isSubscribed) {
      setAssignmentsNum(response.data[0]["COUNT(id)"]);
    }

    return () => (isSubscribed = false);
  }, []);

  useEffect(async () => {
    let isSubscribed = true;
    const response = await axios.get(
      "http://mimapp.mimshakup.com/api/teachers/get-courses-num-timestamp",
      { params: { unix: props.unixTime } }
    );
    // const response = await axios.get(
    //   "http://localhost:7000/api/teachers/get-courses-num-timestamp",
    //   { params: { unix: props.unixTime } }
    // );
    if (isSubscribed) {
      setCoursesTimestamp(response.data[0]["COUNT(id)"]);
    }

    return () => (isSubscribed = false);
  }, [props.unixTime]);

  return (
    <>
      <Header
        coursesNum={coursesNum}
        studentsNum={studentsNum}
        assignmentsNum={assignmentsNum}
        coursesNumTimestamp={coursesTimestamp}
        userEnrolments={userEnrolments}
        unixTime={props.unixTime}
      />
    </>
  );
};

export default HeaderHandler;
